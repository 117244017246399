import ApiService from '@/api/service/apiService.js';
import apiConfig from '@/api/config/config';
var $options = {};
var DynamodbService = {
    AppDetails: function(appId) {
        return new Promise(function(resolve, reject) {
            $options.url = "appDetail?APP_ID="+ appId
            $options.baseUrl=apiConfig.gatWayUrl
            $options.method = "get"
            ApiService.request($options)
                .then(response => {
                    console.log(response,"working3333")
                    resolve(response.data)

                }).catch(error => {
                    reject(error);
                })
        });
    },
    checkAuthLog: function($data) {
        return new Promise(function(resolve, reject) {
            $options.path = "authLog?"+$data
            $options.baseUrl=apiConfig.gatewayUrl
            $options.method = "get"
            ApiService.request($options)
                .then(response => {
                    console.log(response,"working3333")
                    resolve(response.data)
                }).catch(error => {
                    reject(error);
                })
        });
    }
};
export default DynamodbService;