<template>
     <v-text-field density="compact"  variant="outlined">
          </v-text-field>
</template>
<style scoped>

>>> .v-field__outline {
    --v-field-border-width: 0px !important;
    border: 10px !important;
    --v-field-border-opacity: 0.38;
    align-items: stretch;
    contain: layout;
    display: flex;
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    width: 100%;
    border: 1px solid #ADADAD !important;
    border-radius: 4px !important;
}

>>> .v-field--variant-outlined.v-field--focused .v-field__outline {
    --v-field-border-width: 0px !important;
    border: 2px solid #3C5AAA !important;
}

>>> .v-messages__message {
    line-height: 12px;
    word-break: break-word;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    hyphens: auto;
    transition-duration: 150ms;
    text-align: left;
}

</style>