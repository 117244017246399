import ApiService from './apiService';
import apiConfig from '../config/config';
var $options = {};
var UserService = {
    authenticate: function($data) {
        return new Promise(function(resolve, reject) {
            $options.url = "authenticate?"+"token="+$data
            $options.baseUrl=apiConfig.baseUrl
            $options.method = "POST"
            // $options.data = $data
            ApiService.request($options)
                .then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error);
                })
        });
    },
    fireBaseLogin: function($data) {
        return new Promise(function(resolve, reject) {
            $options.url = "authenticate"
            $options.baseUrl=apiConfig.gatWayUrl
            $options.method = "POST"
            $options.data = $data
            ApiService.request($options)
                .then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data);
                })
        });
    },
    forgetPassword: function($data) {
        return new Promise(function(resolve, reject) {
            $options.url = "forgetPassword"
            $options.baseUrl=apiConfig.baseUrl
            $options.method = "POST"
            $options.data = $data
            ApiService.request($options)
                .then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data);
                })
        });
    }
};
export default UserService;