<template>
  <v-row class="body">
    <v-col
      class="pa-13"
      align="center"
      cols="12"
      md="5"
      xl="3"
      lg="4"
      sm="12"
      xs="12"
    >
      <v-form @submit.prevent="resetPassword" ref="form">
        <strong style="font-size: 38px; color: #fff">Synergy</strong>
        <v-card class="card mt-5 pa-8">
          <img :src="require('@/assets/Group20.svg')" alt="wjk" />
          <v-card-title class="LoginTitle">Set New Password</v-card-title>
          <!-- <v-error class="error" v-if="error">Invalide Username And Password</v-error> -->

          <v-col class="pa-0 pt-2">
            <TextFieldComponent
              density="compact"
              @click:append-inner="showPassword1 = !showPassword1"
              placeholder="Password"
              :rules="passwordRule"
              v-model="newPassword"
              variant="outlined"
              @change="passwordError=false"
              v-bind:type="[showPassword1 ? 'text' : 'password']"
              :append-inner-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
            >
            </TextFieldComponent>
          </v-col>
          <v-col class="pa-0 pt-2">
            <TextFieldComponent
              density="compact"
              @click:append-inner="showPassword = !showPassword"
              placeholder="Confirm Password"
              v-model="confirmPassword"
              variant="outlined"
              @change="passwordError=false"
              v-bind:type="[showPassword ? 'text' : 'password']"
              :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            >
            </TextFieldComponent>
            <v-error v-if="passwordError" class="error">{{ message }}</v-error>
          </v-col>
          <!-- <v-col class="pa-0" cols="12" align="center">
            <p style="color: red">{{ message }}</p>
          </v-col> -->
          <v-col class="pa-0 pt-2" cols="12" align="center">
            <v-btn
              block
              color="indigo"
              type="submit"
              size="large"
              :disabled="loading"
              :loading="loading"
              class="text-none mb-4"
              >Reset</v-btn
            >
          </v-col>
          <span class="text-body text-disabled font-weight-medium">
                <v-icon @click="this.$router.push('/')">mdi-arrow-left</v-icon>&nbsp;&nbsp;Back to log in</span
            >
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import TextFieldComponent from "./TextFieldComponent.vue";
import ResetPasswordService from "../api/service/ResetPasswordService.js";
export default {
  components: {
    TextFieldComponent,
  },
  data() {
    return {
      loading:false,
      passwordRule: [
        (value) => {
          if (value) return true;
          return "Password is required";
        },
        (value) => {
          if (/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{6,}/.test(value)) return true;
          return "Password must contain at least lowercase letter, one number, a special character and one uppercase letter";
        },
      ],
      showPassword: false,
      showPassword1: false,
      newPassword: "",
      confirmPassword: "",
      message: null,
      passwordError: false,
    };
  },
  methods: {
    async resetPassword() {
      this.loading=true
            if (this.passwordRule.every((rule) => rule(this.newPassword) === true)) {
                var params = this.$route.query
                // console.log(params,'alert111')
                localStorage.setItem('REDIRECT_URL',params.redirectUrl)

                if (this.newPassword == this.confirmPassword && this.newPassword != "" && this.confirmPassword != "") {
                    this.passwordError = false;
                    var res = await ResetPasswordService.resetPassword({ uid: params.uid, newPassword: this.newPassword, appId: params.appId })
                    console.log(res)
                    this.$router.push({ path: '/success' })
                }
                else {
                  this.loading=false
                    this.passwordError = true;
                    this.message = "Password does not match";
                }
            }
            else {
                this.loading=false
                this.passwordError = true;
                this.message = "Password does not meet the requirements";
            }
        }
  },
};
</script>

<style>
.error {
  line-height: 12px;
  color: red;
  font-family: sans-serif;
  font-size: 16px;
}
</style>