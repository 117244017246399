<template>
  <v-row class="body">
  <v-col
    class="pa-13"
    align="center"
    cols="12"
    md="5"
    xl="3"
    lg="4"
    sm="12"
    xs="12"
  >
    <v-form >
      <strong style="font-size: 38px; color: #fff">Synergy</strong>
      <v-card flat class="card mt-5 pa-8">
        <img :src="require('@/assets/Group30.svg')" alt="wjk" />

        <v-card-title class="LoginTitle">Password reset </v-card-title>
        <span class="text-body-2 text-disabled font-weight-bold"
          >Your password has been successfully reset.Click below to login </span
        >


        <v-card-action class="my-4">
          <v-col class="pa-0 pt-2" cols="12" align="center">
            <v-btn
            block      
            color="indigo"
            class="text-none my-4"
              size="large"
              @click="goToApplication()"

            >Continue</v-btn>
          </v-col>
          <span class="text-body text-disabled font-weight-medium">
              <v-icon @click="goToApplication()">mdi-arrow-left</v-icon>&nbsp;&nbsp;Back to log in</span
          >
        </v-card-action>
      </v-card>
    </v-form>
  </v-col>
</v-row>
</template>

<script>
export default {
  components: {
},
methods:{
  goToApplication(){
    let url =localStorage.getItem('REDIRECT_URL')
    window.location=url
  }
}
}
</script>

<style>

</style>
