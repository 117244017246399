<template>
  <v-row class="body">
    <v-col class="pa-13" align="center" cols="12" md="5" xl="3" lg="4" sm="12" xs="12">
      <v-form>
        <strong style="font-size: 38px; color: #fff">Synergy</strong>
        <v-card flat class="card mt-5 pa-8">
          <img :src="require('@/assets/Group20.svg')" alt="wjk" />

          <v-card-title class="LoginTitle">Forget Password?</v-card-title>
          <span class="text-body-2 text-disabled">No worries,we’ll send you reset instructions.</span>

          <v-card-text class="pa-0 mt-2 pt-2">
            <TextFieldComponent density="compact" placeholder="Your Email" v-model="email"
              :rules="[rules.emailValidation, rules.useremail]" @input="userNotFount=false" variant="outlined">
            </TextFieldComponent>
            <v-error v-if="userNotFount">
              User not found in synergy
            </v-error>
          </v-card-text>

          <v-card-action>
            <v-col class="pa-0 pt-2" cols="12">
              <v-btn color="indigo" block :loading="loading" class="text-none mb-4" @click="sendEmail()">Reset
                Password</v-btn>

            </v-col>
            <span class="text-body text-disabled font-weight-medium">
              <v-icon @click="this.$router.push('/')" class="back">mdi-arrow-left</v-icon>&nbsp;&nbsp;Back to log in</span>
          </v-card-action>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import TextFieldComponent from "./TextFieldComponent.vue";
// import userService from "../api/service/UserService.js"
import ResetPasswordService from '@/api/service/ResetPasswordService'
export default {
  components: {
    TextFieldComponent,
  },
  data() {
    return {
      userNotFount:false,
      email: "",
      loading: false,
      host: "",
      rules: {
        emailValidation: value => !!value || "Email is Required.",
        useremail: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
    }
  },
  methods: {
    async sendEmail() {
      try {
        this.loading = true
        let data = {
          host: window.location.origin,
          email: this.email,
          appId: localStorage.getItem('APP_ID'),
          redirectUrl: localStorage.getItem('REDIRECT_URL')
        }
        localStorage.setItem('PASSWORD_DATA',JSON.stringify(data))
        let response =await ResetPasswordService.forgotPassword(data)
        console.log(response,"hhhhhhhhh")
        if(response.message =='User not found in firebase'){
             this.userNotFount =true
          this.loading =false
        }
        else{
        this.$router.push({ path: '/checkemail', query: { email: this.email,host:window.location.origin } })
        }
      }
      catch (e) {
        this.loading = false
        console.log(e)
      }
    }
  }
};
</script>

<style>


.back{
  cursor:pointer
}
.back:hover{
  color:rgb(104, 104, 246);
  cursor:pointer
}</style>