<template>
    <v-row class="body">
    <v-col
      class="pa-13"
      align="center"
      cols="12"
      md="5"
      xl="3"
      lg="4"
      sm="12"
      xs="12"
    >
      <v-form @submit.prevent="login" ref="form">
        <strong style="font-size: 38px; color: #fff">Synergy</strong>
        <v-card flat class="card mt-5 pa-8">
          <img :src="require('@/assets/Group24.svg')" alt="wjk" />

          <v-card-title class="LoginTitle">Check your email</v-card-title>
          <span class="text-body-2 text-disabled font-weight-bold"
            >We have sent a password reset link to {{Email }}</span
          >

          <v-card-text class="pa-0 my-4 pt-2">
            
            Didn’t receive the email ? <a  @click="resend()" class="resend">Click to resend</a> 
          </v-card-text>

          <v-card-action>
           
            <span class="text-body text-disabled font-weight-medium">
                <v-icon @click="this.$router.push('/')" class="resend">mdi-arrow-left</v-icon>&nbsp;&nbsp;Back to log in</span
            >
          </v-card-action>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
// import userService from "../api/service/UserService.js"
import ResetPasswordService from '@/api/service/ResetPasswordService'
export default {
    components: {
  },
  data(){
    return{
      Email:this.$route.query.email
    }
  },
  methods:{
    async resend() {
      try {
        let data =JSON.parse(localStorage.getItem('PASSWORD_DATA'))
        localStorage.setItem('PASSWORD_DATA',JSON.stringify(data))
        await ResetPasswordService.forgotPassword(data)
      }
      catch (e) {
        this.loading = false
        console.log(e)
      }
    }
  }
  
}
</script>

<style>

.resend{
  cursor:pointer
}
.resend:hover{
  color:rgb(104, 104, 246);
  cursor:pointer
}
</style>