const apiConfig = {
    "baseUrl":" http://localhost:8080",
    "gatWayUrl":"https://79a4z7n2g5.execute-api.ap-south-1.amazonaws.com/dev",
    "dashboard":"http://localhost:8082/",
    "webUrl":"https://axodesk.tiburaglobal.com/login",
    "appName": "Identti Front End",
    "apiKey": "apiKey",
    "appId": "b0dc92bf-3710-4e11-a87d-4d72585cd281",
    "secrect": "secrect"
}
export default apiConfig;


