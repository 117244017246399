import axios from 'axios'
var headers = {
    'Content-Type': 'application/json'
}
var ApiService = {
    request: async function ($options) {
        return new Promise(function (resolve, reject) {
            $options.url = $options.baseUrl + "/" + $options.url;
            if ($options.method == 'POST') {
                let $h
                if ($options.responseType) {
                    $h = { responseType: "blob", headers: headers }
                } else {
                    $h = { headers: headers }
                }
                axios.post($options.url, $options.data, $h)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        console.log(error);
                        // if (error.response.status == 401) {
                        //     resolve(ApiService.refreshToken($options.url, $options))
                        // } else {
                            reject(error)
                        // }
                    });
            } else if ($options.method == 'delete') {
                axios.delete($options.url, { "headers": headers })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        console.log(error);
                        if (error.response.status == 401) {
                            resolve(ApiService.refreshToken($options.url, $options))
                        } else {
                            reject(error)
                        }
                    });
            } else if ($options.method == 'put') {
                axios.put($options.url, $options.data, { headers: headers })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        console.log(error);
                        if (error.response.status == 401) {
                            resolve(ApiService.refreshToken($options.url, $options))
                        } else {
                            reject(error)
                        }
                    });
            } else {
                var $h = {}
                if ($options.responseType) {
                    $h = { responseType: "blob", headers: headers }
                } else {
                    $h = { headers: headers }
                }
                axios.get($options.url, $h)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(async error => {
                        console.log(error);
                    });
            }


        });


    }
};
export default ApiService;