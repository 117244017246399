<template>
  <v-row class="body">
    <v-col class="pa-13" align="center" cols="12" md="5" xl="3" lg="4" sm="12" xs="12">
      <v-form @submit.prevent="login" ref="form">
        <strong style="font-size: 38px; color: #fff">Synergy</strong>
        <v-card class="card mt-5 pa-8" v-if="redirecturl && appId">
          <v-card-title class="LoginTitle">Login to {{ appDetail.name }}</v-card-title>
          <v-error class="error" v-if="error">Invalid Username And Password</v-error>

          <v-col class="pa-0 pt-2">
            <TextFieldComponent class="mt-4" density="compact" placeholder="Your Email" type="email"
              append-inner-icon="mdi-account-outline" :rules="[rules.userRule, rules.emailValidation]" v-model="email"
              variant="outlined">
            </TextFieldComponent>
          </v-col>
          <v-col class="pa-0 pt-2">
            <TextFieldComponent density="compact" @click:append-inner="showPassword = !showPassword"
              placeholder="Password" :rules="passwordRule" v-model="password" variant="outlined"
              v-bind:type="[showPassword ? 'text' : 'password']"
              :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'">
              <!-- <span class="visionIcon"> -->
              <!-- <v-icon @click="showPassword = !showPassword">{{
                showPassword ? "mdi-eye-outline" : "mdi-eye-off-outline"
              }}</v-icon> -->
              <!-- </span> -->
            </TextFieldComponent>
          </v-col>
          <v-col class="pa-0" cols="12" align="center">
            <p style="color: red">{{ message }}</p>
          </v-col>
          <v-col class="pa-0 pt-2" cols="12" align="center">
            <v-btn block color="indigo" type="submit" size="large" :disabled="loading" :loading="loading"
              class="text-none mb-4">Login</v-btn>
          </v-col>

          <v-row no-gutters class="mt-5">
            <v-col><v-divider class="border-opacity-100 mt-3" color="grey-lighten-1"></v-divider></v-col>
            <v-col align="center">OR</v-col>
            <v-col><v-divider class="border-opacity-100 mt-3" color="grey-lighten-1"></v-divider></v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" align="center" class="mt-2">
              <v-btn variant="plain" class="forget" to="/forgetpassword">Forgot your password ?</v-btn>
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-1">
            <v-col class="pt-1" align="center">Trouble Signing in</v-col>
            <!-- <v-col align="end">
            <v-btn color="indigo" variant="plain" to="/signup">Sign up</v-btn>
          </v-col> -->
          </v-row>
        </v-card>
        
<v-card v-else>
&nbsp;
<v-img style="margin: 0 auto;"  width="100px"></v-img>
<v-card-text class="text-red" style="font-weight: 700;">Request did not originate from an Authorized domain!</v-card-text>
</v-card>
      </v-form>
    </v-col>
  </v-row>
  
</template>

<script>
// import UserService from "@/api/service/UserService";
import TextFieldComponent from "./TextFieldComponent.vue";
import userService from "../api/service/UserService.js"
import config from '../api/config/config.js';
import { authStore } from "@/store";

import DynamodbModel from '@/api/service/Dynamodb/DynamodbService'

export default {
  components: {
    TextFieldComponent,
  },
  data() {
    return {
      loading:false,
      error: false,
      host: window.location.host,
      message: null,
      redirecturl: null,
      showPassword: false,
      password: null,
      emailValidation: "",
      passwordRule: [(value) => !!value || "Password is Required."],
      email: null,
      rules: {
        userRule: (value) => !!value || "Email is Required.",
      },
    };
  },
  created() {
    if(this.$route.query.appId){
      let data = {
        appId: this.$route.query.appId,
        sessionId: this.$route.query.sessionId,
        redirectUrl: this.$route.query.redirectUrl
      }
      localStorage.setItem('APP_ID', this.$route.query.appId)
      localStorage.setItem('login_details', JSON.stringify(data));
    }
    let $data=JSON.parse(localStorage.getItem('login_details'))
    this.redirecturl=$data.redirectUrl   
    localStorage.setItem('REDIRECT_URL', this.redirecturl)
    this.appId=$data.appId
    console.log(this.redirecturl,'aleertttt')
    console.log(config)
    this.getAppDetail();
  },
  computed: {
    appDetail() {
      return authStore.getters.getAppDetail;
    },
  },
  methods: {
    async getAppDetail(){
      if(this.$route.query.appId){
        let res = await DynamodbModel.AppDetails(this.$route.query.appId);
        console.log(res,'hlo')
        authStore.commit('setAppDetail',res)
        this.loading=false
      } else {
        this.loading = false;
      }
    },
    async login() {
      if (this.email && this.password) {
        try {
          this.loading=true
          let response = await userService.fireBaseLogin({ email: this.email, password: this.password,appId:this.$route.query.appId })
        
          if (response.token) {
            try{
            // let authResponse = await userService.authenticate(response.token)
            // if (authResponse.data.desks[0].token) {
              window.location = this.redirecturl + "?token=" + response.token
            // }
          }
          catch(e){
          this.error = true
          this.loading=false
            console.log(e)
            }
          }
        } catch (e) {
          this.error = true
          this.loading=false
           
        }
      }
      // else {
      //    if(response.data){
      //   this.error = true
      //    }
      // }
      

    },
  },
};
</script>
<style>
.forget{
  cursor:pointer
}
.forget:hover{
  color:rgb(104, 104, 246);
  cursor:pointer
}
.error {
  color:rgb(255, 0, 0);
  font-size: 15px;
}
</style>
