import ApiService from '@/api/service/apiService.js';
import GatewayService from "@/api/service/GatewayService1.js";
import apiConfig from '../config/config';

var $options = {}
var ResetPasswordService = {
    forgotPassword: function ($data) {
        return new Promise(function (resolve, reject) {
            $options.url = 'forgotPassword'
            $options.baseUrl = apiConfig.gatWayUrl
            $options.method = "POST"
            $options.data = $data
            ApiService.request($options)
                .then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
        })
    },
    resetPassword: function ($data) {
        return new Promise(function (resolve, reject) {
            $options.path = 'resetPassword'
            $options.baseUrl = apiConfig.gatWayUrl
            $options.method = "POST"
            $options.data = $data
            GatewayService.request($options)
                .then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
        })
    },

    // resetPassword: function ($data) {
    //     return new Promise(function (resolve, reject) {
    //         $options.path = 'resetPassword'
    //         $options.baseUrl = apiConfig.gatWayUrl
    //         $options.method = "POST"
    //         $options.data = $data
    //         GatewayService.request($options)
    //             .then(response => {
    //                 resolve(response.data)
    //             }).catch(error => {
    //                 reject(error)
    //             })
    //     })
    // },
    setNewPassword: function ($data) {
        return new Promise(function (resolve, reject) {
            $options.url = 'setNewPassword'
            $options.baseUrl = apiConfig.gatWayUrl
            $options.method = "POST"
            $options.data = $data
            ApiService.request($options)
                .then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
        })
    }
}

export default ResetPasswordService
