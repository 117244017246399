import { createRouter, createWebHistory } from 'vue-router'
import Login from '../components/Login.vue'
import ResetPassword from '../components/ResetPassword.vue'
import Forget from '../components/Forget.vue'
import Check from '../components/Check.vue'
import Success from '../components/Success.vue'
const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path:'/resetPassword',
    name:'ResetPassword',
    component:ResetPassword
  },
  {
    path:'/forgetpassword',
    name:'Forget',
    component:Forget
  },
  {
    path:'/checkemail',
    name:'Check',
    component:Check
  },
  {
    path:'/success',
    name:'Success',
    component:Success
  },
 
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
