<template>
    <div>
      <!-- <Logo/> -->
      <router-view></router-view>
    </div>
</template>
<script>
// import Logo from '@/components/Logo.vue';

export default ({
  name: 'App',
  components:{
    // Logo
  },

})
</script>

<style lang="scss">
@import '../src/scss/style.scss';
// .body{
//   background-color:#F2F7FD;
// }
</style>
