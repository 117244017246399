import { createStore } from "vuex";

//
// @see https://github.com/vuejs/vuex/tree/v4.0.0-rc.1
//
export const authStore = createStore({
  state() {
    return {
        appDetail:""
    };
  },
  getters: {
    getAppDetail(state) {      
      return state.appDetail;
    }
  },
  // ACTIONS (asynchronous)
  actions: {
  },

  // MUTATIONS ( set the state )
  mutations: {
    setAppDetail(state,payload) {
      state.appDetail = payload
    }
  }
});

